import "core-js/stable";
import "regenerator-runtime/runtime";
import Slider from "./js/Slider";
import "./app.scss";
import Menu from "./js/Menu";
import lazySizes from "lazysizes";
import GlobeContainer from "./js/GlobeContainer";
import Popup from "./js/Popup";
import Video from "./js/Video";
import Alpine from "alpinejs";

Alpine.start();

let actions = {};
let pageExit = {};

function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

runActions();

function runActions() {
  action("Slider", Slider);
  Menu(document.querySelectorAll(".menu-btn"));
  action("GlobeContainer", GlobeContainer);
  Popup(document.querySelectorAll(".popup-btn"));
  action("Video", Video);
}

// (function () {
//   function getFlooredFixed(v, d) {
//     return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
//   }

//   const settings = {
//     async: true,
//     crossDomain: true,
//     url: "https://yh-finance.p.rapidapi.com/stock/v2/get-financials?symbol=RYR.V&region=US",
//     method: "GET",
//     headers: {
//       "x-rapidapi-host": "yh-finance.p.rapidapi.com",
//       "x-rapidapi-key": "79b6d477b9msh5b5f70eef02149ap1dd223jsna408e88b858b",
//     },
//   };

//   const xhr = new XMLHttpRequest();
//   xhr.open(settings.method, settings.url, settings.async);
//   xhr.setRequestHeader("x-rapidapi-host", settings.headers["x-rapidapi-host"]);
//   xhr.setRequestHeader("x-rapidapi-key", settings.headers["x-rapidapi-key"]);
//   xhr.onload = function () {
//     if (xhr.status === 200) {
//       const response = JSON.parse(xhr.responseText);
//       const element = document.querySelector(".tsx");
//       const change = document.querySelector(".tsx-change");

//       if (!response) return;

//       element.textContent =
//         response.price.currency +
//         response.price.currencySymbol +
//         getFlooredFixed(response.price.regularMarketOpen.raw, 2);

//       change.textContent =
//         "[" + response.price.regularMarketChangePercent.fmt + "]";

//       document.querySelector(".stock")?.classList.add("active");
//     } else {
//       console.error(xhr.statusText);
//     }
//   };
//   xhr.onerror = function () {
//     console.error(xhr.statusText);
//   };
//   xhr.send();

//   const fra_settings = {
//     async: true,
//     crossDomain: true,
//     url: "https://yh-finance.p.rapidapi.com/stock/v2/get-financials?symbol=RLU.F&region=US",
//     method: "GET",
//     headers: {
//       "x-rapidapi-host": "yh-finance.p.rapidapi.com",
//       "x-rapidapi-key": "79b6d477b9msh5b5f70eef02149ap1dd223jsna408e88b858b",
//     },
//   };

//   const fra_xhr = new XMLHttpRequest();
//   fra_xhr.open(fra_settings.method, fra_settings.url, fra_settings.async);
//   fra_xhr.setRequestHeader(
//     "x-rapidapi-host",
//     fra_settings.headers["x-rapidapi-host"]
//   );
//   fra_xhr.setRequestHeader(
//     "x-rapidapi-key",
//     fra_settings.headers["x-rapidapi-key"]
//   );
//   fra_xhr.onload = function () {
//     if (fra_xhr.status === 200) {
//       const response = JSON.parse(fra_xhr.responseText);
//       const element = document.querySelector(".fra");
//       const change = document.querySelector(".fra-change");

//       if (!response) return;

//       element.textContent =
//         response.price.currency +
//         response.price.currencySymbol +
//         getFlooredFixed(response.price.regularMarketOpen.raw, 2);

//       change.textContent =
//         "[" + response.price.regularMarketChangePercent.fmt + "]";

//       document.querySelector(".stock")?.classList.add("active");
//     } else {
//       console.error(fra_xhr.statusText);
//     }
//   };
//   fra_xhr.onerror = function () {
//     console.error(fra_xhr.statusText);
//   };
//   fra_xhr.send();
// })();
